import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";

function Footer() {
  const isContactPage = window.location.pathname === "/contact";
  return (
    <>
      <footer className="text-center">
        <Container className="p-4">
          <Row className="align-items-center">
            <Col className="order-md-1 order-3" md={isContactPage ? 12 : 4}>
              <img src="logo.png" width={120} className="img-fluid" alt="" />
            </Col>
            {!isContactPage && (
              <>
                <Col className="order-md-2" md={4}>
                  <h4 className="fw-bold">Contactanos</h4>
                  <div>
                    <i className="fa-regular fa-envelope me-2"></i>
                    <a href="mailto:informes@ranchoporfin.com">
                      informes@ranchoporfin.com
                    </a>
                  </div>
                  <div>
                    <i className="fa-brands fa-whatsapp me-2"></i>
                    <a href="https://wa.me/5492644456494">+54 9 264 445-6494</a>
                  </div>
                </Col>
                <Col className="order-md-3 p-3" md={4}>
                  <span className="fs-2">
                    <i
                      onClick={() =>
                        window.open("https://www.facebook.com/ranchoporfin")
                      }
                      className="fa-brands fa-facebook-f me-4 cursor-pointer"
                    ></i>
                    <i
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/rancho_porfin_barreal/"
                        )
                      }
                      className="fa-brands fa-instagram cursor-pointer"
                    ></i>
                  </span>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;

import { useEffect, useRef, useState } from "react";
import { Container, Form } from "react-bootstrap";
import HeaderBanner from "../../components/header-banner/HeaderBanner";
import Subtitle from "../../components/Subtitle";
import "./Tourism.css";

function Tourism() {
  const tabs = [
    { id: "tab1", label: "Trekking" },
    { id: "tab2", label: "Moto" },
    { id: "tab3", label: "Cabalgata" },
    { id: "tab4", label: "Bici" },
    { id: "tab5", label: "4x4" },
    { id: "tab6", label: "Aventura" },
  ];

  const cardData = {
    tab1: [
      {
        id: 1,
        title: "Cordillera de Ansilta",
        image: "img/tourism/cordillera.webp",
      },
      { id: 2, title: "Laguna Blanca", image: "img/tourism/lagunaBlanca.webp" },
      { id: 3, title: "Teta Colorada", image: "img/tourism/tetaColorada.webp" },
      {
        id: 4,
        title: "Arroyo Turquesa",
        image: "img/tourism/arroyoTurquesa.webp",
      },
      {
        id: 5,
        title: "Cerro El Leoncito",
        image: "img/tourism/cerroElLeoncito.webp",
      },
      {
        id: 6,
        title: "Los Tres Escalones",
        image: "img/tourism/losTresEscalones.webp",
      },
      {
        id: 7,
        title: "Parque Nacional El Leoncito",
        image: "img/tourism/parqueNacionalElLeoncito.webp",
      },
    ],
    tab2: [
      {
        id: 8,
        title: "Pampa del Leoncito",
        image: "img/tourism/pampaDelLeoncito.webp",
      },
      { id: 9, title: "Laguna Blanca", image: "img/tourism/lagunaBlanca.webp" },
      {
        id: 10,
        title: "Mirador Calle Huarpes",
        image: "img/tourism/miradorCalleHuarpes.webp",
      },
      {
        id: 11,
        title: "Miradores de Calingasta",
        image: "img/tourism/miradoresDeCalingasta.webp",
      },
    ],
    tab3: [
      {
        id: 12,
        title: "Calle los Enamorados",
        image: "img/tourism/calleLosEnamorados.webp",
      },
      {
        id: 13,
        title: "Rio de los Patos",
        image: "img/tourism/rioDeLosPatos.webp",
      },
      {
        id: 14,
        title: "Teta Colorada",
        image: "img/tourism/tetaColorada.webp",
      },
    ],
    tab4: [
      {
        id: 15,
        title: "Capilla Nuestra Señora del Carmen",
        image: "img/tourism/capillaDelCarmen.webp",
      },
      {
        id: 16,
        title: "Calle los Enamorados",
        image: "img/tourism/calleLosEnamorados.webp",
      },
      {
        id: 17,
        title: "Cristo de la Misericordia",
        image: "img/tourism/cristoMisericordia.webp",
      },
      {
        id: 18,
        title: "Miradores de Calingasta",
        image: "img/tourism/miradoresDeCalingasta.webp",
      },
      {
        id: 19,
        title: "Teta Colorada",
        image: "img/tourism/tetaColorada.webp",
      },
    ],
    tab5: [
      {
        id: 20,
        title: "Pampa del Leoncito",
        image: "img/tourism/pampaDelLeoncito.webp",
      },
      {
        id: 21,
        title: "Laguna Blanca",
        image: "img/tourism/lagunaBlanca.webp",
      },
      {
        id: 22,
        title: "Las Hornillas",
        image: "img/tourism/losHornillos.webp",
      },
      {
        id: 23,
        title: "Sierras del Tontal",
        image: "img/tourism/sierraDelTontal.webp",
      },
      { id: 24, title: "Los Morillos", image: "img/tourism/losMorillos.webp" },
      {
        id: 25,
        title: "Bosque Petrificado",
        image: "img/tourism/bosquePetrificado.webp",
      },
    ],
    tab6: [
      { id: 26, title: "Carrovelismo", image: "img/tourism/carrovelismo.webp" },
      { id: 27, title: "Rafting", image: "img/tourism/rafting.webp" },
      { id: 28, title: "Escalada", image: "img/tourism/escalada.webp" },
      {
        id: 29,
        title: "Expedición Andina",
        image: "img/tourism/expedicionAndina.webp",
      },
      { id: 30, title: "Astronomía", image: "img/tourism/astronomia.webp" },
      {
        id: 31,
        title: "Cruce de los Andes",
        image: "img/tourism/cruceDeLosAndes.webp",
      },
    ],
  };

  const cantMiss = [
    { id: 1, title: "CESCO ", image: "img/tourism/cesco.webp" },
    { id: 2, title: "Cerro Alcázar", image: "img/tourism/cerroAlcazar.webp" },
    {
      id: 3,
      title: "Cerro 7 Colores",
      image: "img/tourism/cerro7Colores.webp",
    },
    {
      id: 4,
      title: "Museo Natural con Petroglifos ",
      image: "img/tourism/museoNatural.webp",
    },
    {
      id: 5,
      title: "Pampa del Leoncito",
      image: "img/tourism/pampaDelLeoncito.webp",
    },
    {
      id: 6,
      title: "Mirador de Cordón Andino",
      image: "img/tourism/miradorCordonAndino.webp",
    },
    { id: 7, title: "CASLEO", image: "img/tourism/casleo.webp" },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const containerRef = useRef(null);
  const cantMissContainerRef = useRef(null);

  useEffect(() => {});

  const scrollLeft = (containerRef) => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -scrollValue(),
        behavior: "smooth",
      });
    }
  };

  const scrollRight = (containerRef) => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: scrollValue(),
        behavior: "smooth",
      });
    }
  };

  const scrollValue = () => {
    const factor = 0.6; // Factor of the viewport width, adjust as needed
    return window.innerWidth * factor;
  };

  return (
    <div>
      <HeaderBanner image={"img/tourism_banner.webp"}></HeaderBanner>
      <Container className="mt-5">
        <Subtitle text={"Turismo en la zona"}></Subtitle>
      </Container>

      <Container className="mt-4 ">
        <Form className="mb-3 justify-content-center d-flex">
          <div className="tabs-container">
            {tabs.map((tab) => (
              <div className="simple-tab" key={tab.id}>
                <Form.Check
                  type="radio"
                  id={tab.id}
                  name="tabs"
                  label={tab.label}
                  checked={activeTab === tab.id}
                  onChange={() => {
                    setActiveTab(tab.id);
                  }}
                  className="btn-check"
                />
                <label
                  className={`tab-label w-100 ${
                    activeTab === tab.id ? "active" : ""
                  }`}
                  htmlFor={tab.id}
                >
                  {tab.label}
                </label>
              </div>
            ))}
          </div>
        </Form>

        <Container className="mt-4">
          <div className="activities-wrapper">
            <div
              className="arrow-left"
              onClick={() => scrollLeft(containerRef)}
            >
              <i className="fa fa-arrow-left"></i>
            </div>
            <div className="activities-container" ref={containerRef}>
              {cardData[activeTab]?.map((card) => (
                <div
                  key={card.id}
                  className="activities-card"
                  style={{
                    backgroundImage: `url(${card.image})`,
                  }}
                >
                  <span>{card.title}</span>
                </div>
              ))}
            </div>
            <div
              className="arrow-right"
              onClick={() => scrollRight(containerRef)}
            >
              <i className="fa fa-arrow-right"></i>
            </div>
          </div>
        </Container>
      </Container>

      <Container className="mt-5 mb-5">
        <Subtitle text={"No te podes perder"}></Subtitle>
      </Container>

      <Container className="mb-3">
        <div className="cantmiss-wrapper">
          <div
            className="arrow-left"
            onClick={() => scrollLeft(cantMissContainerRef)}
          >
            <i className="fa fa-arrow-left"></i>
          </div>
          <div className="cantmiss-container" ref={cantMissContainerRef}>
            {cantMiss.map((card) => (
              <div key={card.id} className="cantmiss-card">
                <div
                  className="cantmiss-image"
                  style={{ backgroundImage: `url(${card.image})` }}
                ></div>
                <span>{card.title}</span>
              </div>
            ))}
          </div>
          <div
            className="arrow-right"
            onClick={() => scrollRight(cantMissContainerRef)}
          >
            <i className="fa fa-arrow-right"></i>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Tourism;

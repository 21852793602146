import { Col, Container, Row } from "react-bootstrap";
import HeaderBanner from "../../components/header-banner/HeaderBanner";
import Subtitle from "../../components/Subtitle";

function Contact() {
  return (
    <div>
      <HeaderBanner image={"img/mainpage_banner.webp"}></HeaderBanner>
      <Container className="mt-4 text-primary">
        <Row className="align-items-center text-center">
          <Col md={6}>
            <h4 className="fw-bold">Contactanos</h4>
            <div>
              <i className="fa-regular fa-envelope me-2"></i>
              <a href="mailto:informes@ranchoporfin.com">
                informes@ranchoporfin.com
              </a>
            </div>
            <div>
              <i className="fa-brands fa-whatsapp me-2"></i>
              <a href="https://wa.me/5492644456494">+54 9 264 445-6494</a>
            </div>
          </Col>
          <Col className="order-md-3 p-3" md={6}>
            <h4 className="fw-bold">Redes Sociales</h4>
            <span className="fs-2">
              <i
                onClick={() =>
                  window.open("https://www.facebook.com/ranchoporfin")
                }
                className="fa-brands fa-facebook-f me-4 cursor-pointer"
              ></i>
              <i
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/rancho_porfin_barreal/"
                  )
                }
                className="fa-brands fa-instagram cursor-pointer"
              ></i>
            </span>
          </Col>
        </Row>
      </Container>
      <Container className="mt-4">
        <Subtitle text={"Ubicación"}></Subtitle>
      </Container>
      <Container className="text-center mt-4 mb-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3397.077474217363!2d-69.46521372427462!3d-31.631729074163268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x968691e507d5330b%3A0xdc4ab5f4d0673f2e!2sRancho%20Por%20Fin!5e0!3m2!1ses!2sar!4v1725331325260!5m2!1ses!2sar"
          height="450"
          style={{ border: "0", width: "70vw" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Container>
    </div>
  );
}
export default Contact;

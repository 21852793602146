import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Mainpage from "./pages/mainpage/Mainpage";
import Contact from "./pages/contact/Contact";
import History from "./pages/history/History";
import Apartments from "./pages/apartments/Apartments";
import Tourism from "./pages/tourism/Tourism";
import PageNavbar from "./components/navbar/PageNavbar";
import Footer from "./components/footer/Footer";
import Gardens from "./pages/gardens/Gardens";
import "react-medium-image-zoom/dist/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: Mainpage(),
  },
  {
    path: "/contact",
    element: Contact(),
  },
  {
    path: "/history",
    element: History(),
  },
  {
    path: "/apartments",
    element: <Apartments></Apartments>,
  },
  {
    path: "/tourism",
    element: <Tourism></Tourism>,
  },
  {
    path: "/gardens",
    element: <Gardens></Gardens>,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PageNavbar></PageNavbar>
    <RouterProvider router={router}></RouterProvider>
    <Footer></Footer>
  </React.StrictMode>
);

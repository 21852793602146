import { Container } from "react-bootstrap";
import HeaderBanner from "../../components/header-banner/HeaderBanner";
import Subtitle from "../../components/Subtitle";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect } from "react";

function Gardens() {
  const gardens = Array.from({ length: 15 }, (_, i) => i + 1);
  const guests = Array.from({ length: 7 }, (_, i) => i + 1);
  const outsideRoom = Array.from({ length: 4 }, (_, i) => i + 1);
  const pool = Array.from({ length: 4 }, (_, i) => i + 1);

  useEffect(() => {}, []);

  return (
    <div>
      <HeaderBanner image={"img/gardens_banner.webp"}></HeaderBanner>
      <Container className="mt-5 mb-5">
        <Subtitle text={"Jardines"}></Subtitle>
      </Container>

      <Container className="w-md-75">
        <Carousel
          showArrows={true}
          dynamicHeight={true}
          showThumbs={false}
          infiniteLoop={true}
        >
          {gardens.map((garden) => (
            <DivImage
              key={garden}
              imageSrc={`img/gardens/gardens/${garden}.jpg`}
            ></DivImage>
          ))}
        </Carousel>
      </Container>

      <Container className="mt-5 mb-5">
        <Subtitle text={"Quincho"}></Subtitle>
      </Container>
      <Container className="w-md-75">
        <Carousel
          showArrows={true}
          dynamicHeight={true}
          showThumbs={false}
          infiniteLoop={true}
        >
          {outsideRoom.map((room) => (
            <DivImage
              key={room}
              imageSrc={`img/gardens/outside-room/${room}.jpg`}
            ></DivImage>
          ))}
        </Carousel>
      </Container>

      <Container className="mt-5 mb-5">
        <Subtitle text={"Pileta"}></Subtitle>
      </Container>
      <Container className="w-md-75">
        <Carousel
          showArrows={true}
          dynamicHeight={true}
          showThumbs={false}
          infiniteLoop={true}
        >
          {pool.map((pool) => (
            <DivImage
              key={pool}
              imageSrc={`img/gardens/pool/${pool}.jpg`}
            ></DivImage>
          ))}
        </Carousel>
      </Container>

      <Container className="mt-5 mb-5">
        <Subtitle text={"Huéspedes"}></Subtitle>
      </Container>
      <Container className="mb-5 w-md-75">
        <Carousel
          showArrows={true}
          dynamicHeight={true}
          showThumbs={false}
          infiniteLoop={true}
        >
          {guests.map((guest) => (
            <DivImage
              key={guest}
              imageSrc={`img/gardens/guests/${guest}.jpg`}
            ></DivImage>
          ))}
        </Carousel>
      </Container>
    </div>
  );
}

function DivImage({ imageSrc }) {
  return (
    <div
      style={{
        backgroundImage: `url(${imageSrc})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: "20px",
        height: window.innerWidth > 768 ? "30rem" : "15rem",
      }}
    ></div>
  );
}

export default Gardens;

import { Container } from "react-bootstrap";
import HeaderBanner from "../../components/header-banner/HeaderBanner";
import Subtitle from "../../components/Subtitle";
import Zoom from "react-medium-image-zoom";

function History() {
  return (
    <div>
      <HeaderBanner image={"img/history_banner.webp"}></HeaderBanner>
      <Container className="mt-5">
        <Subtitle text={"Historia de Rancho Por Fin"}></Subtitle>
      </Container>
      <Container className="d-flex justify-content-center ">
        <p className="text-justify w-100 w-md-75 px-3 px-md-0 mt-4">
          Cuando Sarmiento escapó hacia Chile, pasó por Zonda donde dejó tallado
          en piedra el histórico mensaje: “Las ideas no se matan”. <br />
          Continuó su viaje hacia la localidad de Tamberías, pasando en ese
          entonces por el único camino existente, con una parada de descanso en
          Barreal. En esa época ya existía el “Rancho Por Fin”. Cuentan los
          pobladores que el prócer se alojó allí ya que en este sitio funcionaba
          la única escuela de la zona, hecho relatado por los ancianos del lugar
          y transmitido de boca en boca por sus descendientes. El «Rancho Por
          Fin» transitó por diferentes usos: escuela, pulpería, residencia
          familiar, terminando como depósito de anís y alfalfa, cuando un grupo
          familiar lo descubre y lo acondiciona para vacacionar. Años
          posteriores se restaura para compartir y brindar los mejores servicios
          turísticos, para gozar de un hermoso descanso entre la paz de la
          naturaleza y las paredes de adobe que propician un ambiente fresco y
          lleno de historias. <br /> De este modo surgen dos complejos
          habitacionales, “POR FIN LO ENCONTRAMOS” y “POR FIN LO ARREGLAMOS”,
          con características que evocan toda una historia en este lugar al pie
          de la cordillera. Este grupo familiar durante muchos años estuvo
          buscando su lugar soñado… “RANCHO POR FIN”… y ahora abre sus puertas a
          todos los que quieran descubrirlo. <br /> <br />
          <b>
            Memoria Técnica del Contexto Arquitectónico– Cultural de Rancho Por
            Fin San Juan, Noviembre de 2012.-
          </b>
          A continuación se enumeran los valores patrimoniales contenidos en la
          propiedad conocida como Rancho Por Fin, en calle Belgrano s/n,
          Barreal, Dpto. Calingasta, San Juan. A- Valores constructivos: La
          estructura portante y de cierre se encuentra materializada por un muro
          de tapia de 80 cm. de espesor. En su relación con la altura de 4m.
          Aproximadamente, le otorga a este una capacidad antisísmica
          particular. Si a esto se le suma el balance existente entre muros que
          corren de norte a sur y de este a oeste, se explica la entereza de la
          estructura que no muestra fisuras. El hecho de tener una base de
          piedra sobre el cual se funda la tapia le suma capacidad antisísmica
          al conjunto estructural. B- Valor tipológico: La composición de las
          partes se ordena a partir de un eje que atraviesa el centro de la
          arcada de la casa, y este eje organiza en su recorrido simétricamente
          los espacios incluyendo: La galería, el patio, y el sitio, éste ultimo
          de reconocida herencia chilena. Muros de notable espesor (0,80m.),
          aberturas pequeñas y techos de tirantería achuelada configuran un
          complejo estructural coherente y de gran resistencia. C- Valores
          históricos: Si se analiza el contexto en el cual se halla localizado
          el Rancho Por Fin y su relación con otras construcciones, podemos
          definir que: a) Se encuentra en el antiguo portal de acceso al Valle
          de Barreal, considerando que a pocos metros se encuentra la calle
          Mendoza que da continuidad a la quebrada de las minas, que era el
          último tramo del camino San Juan –Calingasta- Chile, por Maradona. b)
          Sobre este viejo portal, calle Belgrano, hoy Callejón de los
          Enamorados, se localizan las ruinas de lo que fuera la comisaría y el
          correo. c) Esto explica los diversos usos que tuvo el actual Rancho
          por Fin a lo largo de su historia: Depósito de granos, pulpería,
          escuela, vivienda y actualmente alojamiento turístico. d) Es muy
          probable que los viajeros que cruzaban la pre cordillera por Maradona,
          luego de la travesía encontraban al Valle de Barreal como un oasis
          para descansar antes de su viaje a Chile. No es de extrañar, que como
          dice la tradición popular, Sarmiento en su camino al exilio a Chile
          haya parado en el Rancho Por Fin. D- Valor patrimonial: Nos
          encontramos en un caso claro de patrimonio edilicio contextual no
          monumental. Este es el caso de una obra modesta que ha adquirido con
          el tiempo un significado cultural. El uso de materiales autóctonos, el
          desarrollo de sistemas artesanales de construcción basadas en
          tecnología ancestral que aplicada con ingenio controla la humedad, el
          clima, la ventilación y toda una batería de elementos conciliadores
          del medio ambiente. <br /> Por estas razones, se considera a esta
          construcción de un alto valor patrimonial. <br /> <br /> Autor:
          Arquitecto Eduardo Grizas. “Miembro de ICOMOS” (Consejo Internacional
          de Monumentos y Sitios Histórico-Artísticos).
        </p>
      </Container>

      <Container className="mt-5 mb-5 text-center">
        <Zoom>
          <img src="img/history.webp" alt="" className="rounded img-fluid" />
        </Zoom>
      </Container>
    </div>
  );
}
export default History;

import "./HeaderBanner.css";

function HeaderBanner({ image }) {
  return (
    <>
      <div className="crop-container">
        <img src={image} alt="" className="img-fluid image-banner" />
      </div>
    </>
  );
}

export default HeaderBanner;

import { Col, Container, Row } from "react-bootstrap";
import HeaderBanner from "../../components/header-banner/HeaderBanner";
import "./Mainpage.css";
import Subtitle from "../../components/Subtitle";
import Zoom from "react-medium-image-zoom";

function Mainpage() {
  const stations = [
    {
      img: "img/station_1.webp",
      name: "Verano",
    },
    {
      img: "img/station_3.webp",
      name: "Otoño",
    },
    {
      img: "img/station_4.webp",
      name: "Invierno",
    },
    {
      img: "img/station_2.webp",
      name: "Primavera",
    },
  ];

  const campingZones = [
    {
      img: "img/camping_1.webp",
      name: "Camping 1",
    },
    {
      img: "img/camping_2.webp",
      name: "Camping 2",
    },
    {
      img: "img/camping_3.webp",
      name: "Camping 3",
    },
    {
      img: "img/camping_4.webp",
      name: "Camping 4",
    },
  ];

  return (
    <div>
      <HeaderBanner image={"img/mainpage_banner.webp"}></HeaderBanner>
      <Row
        className="text-center gx-0 -mt-5 px-3 px-md-5"
        style={{ position: "relative" }}
      >
        <Col xs={12} md={6} lg={3} className="p-2">
          <SectionCard
            text={"Departamentos"}
            image={"img/apartments_banner.webp"}
            route={"/apartments"}
          ></SectionCard>
        </Col>
        <Col xs={12} md={6} lg={3} className="p-2">
          <SectionCard
            text={"Jardines"}
            image={"img/gardens_banner.webp"}
            route={"/gardens"}
          ></SectionCard>
        </Col>
        <Col xs={12} md={6} lg={3} className="p-2">
          <SectionCard
            text={"Turismo"}
            image={"img/tourism_banner.webp"}
            route={"/tourism"}
          ></SectionCard>
        </Col>
        <Col xs={12} md={6} lg={3} className="p-2">
          <SectionCard
            text={"Historia"}
            image={"img/history_banner.webp"}
            route={"/history"}
          ></SectionCard>
        </Col>
      </Row>

      <Container className="mt-4 text-center">
        <img src="img/glyph_stations.png" alt="" />
      </Container>
      <Container className="mt-5">
        <Subtitle text={"Estaciones en nuestro complejo"}></Subtitle>
      </Container>
      <Container className="mt-5 stations-container">
        {stations.map((item, index) => (
          <div key={index} className="stations-card p-4">
            <img src={item.img} alt="" />
            <span>{item.name}</span>
          </div>
        ))}
      </Container>

      <Container className="mt-5 text-center">
        <img src="img/glyph_camping.png" alt="" />
      </Container>
      <Container className="mt-3">
        <Subtitle text={"Zona de acampe"}></Subtitle>
      </Container>
      <Container className="mt-5 stations-container">
        {campingZones.map((item, index) => (
          <div
            key={index}
            style={{ background: "unset" }}
            className="stations-card"
          >
            <img src={item.img} alt="" />
          </div>
        ))}
      </Container>

      <Container className="mt-5 text-center">
        <img src="img/glyph_location.png" alt="" />
      </Container>
      <Container className="mt-4">
        <Subtitle text={"Ubicación"}></Subtitle>
      </Container>
      <Container className="text-center mt-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3397.077474217363!2d-69.46521372427462!3d-31.631729074163268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x968691e507d5330b%3A0xdc4ab5f4d0673f2e!2sRancho%20Por%20Fin!5e0!3m2!1ses!2sar!4v1725331325260!5m2!1ses!2sar"
          height="450"
          style={{ border: "0", width: "80vw" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Container>

      <Container className="text-center bg-terciary-light w-75 w-md-50 w-lg-25 p-3 mt-5 rounded text-primary fw-medium">
        <span>
          Consultá por alquiler mensual para proyectos científicos y mineros.
        </span>
      </Container>

      <Container className="mt-5 text-center mb-5">
        <span
          className="calendar-button"
          onClick={() => {
            window.open(
              "https://www.argentina.gob.ar/interior/feriados-nacionales-2024"
            );
          }}
        >
          <i className="fa-regular fa-calendar me-2"></i>
          <span className="text-decoration-underline">
            Calendario de feriados
          </span>
        </span>
      </Container>
    </div>
  );
}

function SectionCard({ image, text, route }) {
  const style = {
    backgroundImage: `url(${image})`,
  };

  const navigate = function (path) {
    window.location.href = path;
  };

  return (
    <div
      style={style}
      className="section-card"
      onClick={() => navigate(`${route}`)}
    >
      <h3 className="text-white fw-bold">{text}</h3>
    </div>
  );
}

export default Mainpage;

function Subtitle({ text, style }) {
  return (
    <div className="text-center">
      <h3 style={style} className="fw-bold text-primary">
        {text}
      </h3>
    </div>
  );
}

export default Subtitle;

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./PageNavbar.css";
import { useEffect, useState } from "react";

function PageNavbar() {
  const location = window.location.pathname;
  const [navbarBg, setNavbarBg] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setNavbarBg(true);
      } else {
        setNavbarBg(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navbarBg]);

  return (
    <>
      {["md"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className={`mb-3 page-navbar ${navbarBg ? "navbar-scrolled" : ""}`}
          fixed="top"
        >
          <Container fluid>
            <Navbar.Brand href="/">
              <img
                src="logo_no_text.png"
                className="img-fluid"
                width={70}
                alt=""
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="bg-canvas"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <Navbar.Brand href="/">
                    <img
                      src="logo_no_text.png"
                      className="img-fluid"
                      width={70}
                      alt=""
                    />
                  </Navbar.Brand>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end text-center flex-grow-1 pe-3">
                  <Nav.Link
                    className={location === "/" ? "active" : ""}
                    href="/"
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    className={location === "/apartments" ? "active" : ""}
                    href="apartments"
                  >
                    Departamentos
                  </Nav.Link>
                  <Nav.Link
                    className={location === "/tourism" ? "active" : ""}
                    href="tourism"
                  >
                    Turismo
                  </Nav.Link>
                  <Nav.Link
                    className={location === "/gardens" ? "active" : ""}
                    href="gardens"
                  >
                    Jardínes
                  </Nav.Link>
                  <Nav.Link
                    className={location === "/history" ? "active" : ""}
                    href="history"
                  >
                    Historia
                  </Nav.Link>
                  <Nav.Link
                    className={location === "/contact" ? "active" : ""}
                    href="contact"
                  >
                    Contacto
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default PageNavbar;
